import { APP_API_URL } from "../../constants/urls";

import getClient from "../../client";
import { getAccessContext } from "../../helpers/access";
import { scormLessonPath } from "../../routes/scorm";

/**
 * Used by 1st-party SCORM after authentication to redirect
 * to the access route.
 * @returns {Promise<string>} The route to access the lesson
 */
export const getScormAccessRoute = () => {
  const { magicKey, lessonKey } = getScormParams();
  if (!magicKey || !lessonKey) {
    return null;
  }

  return `/scorm/${magicKey}/${lessonKey}`;
};

/**
 * After authentication, get the path to the lesson itself.
 * @param {string} lessonKey Unique string identifier for a lesson
 * @returns {Promise<string>} The path to the lesson
 */
export const getLessonPath = async lessonKey => {
  const context = getAccessContext();
  const scormPath = await getClient()
    .get(`${APP_API_URL}/scorm/v1/${lessonKey}`)
    .then(response => {
      return scormLessonPath(context, response.data.levelId, response.data.lessonId);
    });

  return scormPath;
};

/**
 * Retrieve the SCORM parameters from session storage
 * @returns {Object} The SCORM parameters
 * @returns {string} magicKey
 * @returns {string} lessonKey
 * @returns {string} scormIdentity
 */
export const getScormParams = () => {
  return {
    magicKey: sessionStorage.getItem("magicKey"),
    lessonKey: sessionStorage.getItem("lessonKey"),
    scormIdentity: sessionStorage.getItem("scormIdentity"),
  };
};

/**
 * Save the SCORM parameters to session storage
 * @param {Object} params The SCORM parameters
 * @param {string} params.magicKey
 * @param {string} params.lessonKey
 * @param {string} params.scormIdentity
 */
export const saveScormParams = ({ magicKey, lessonKey, scormIdentity }) => {
  sessionStorage.setItem("magicKey", magicKey);
  sessionStorage.setItem("lessonKey", lessonKey);
  sessionStorage.setItem("scormIdentity", scormIdentity);
};

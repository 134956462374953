import { retrieveAuthToken } from "../authService";
import { DirectoryClient } from "@securityjourney/svc-directory";
import { APP_URL_HOST } from "../../constants/urls";

export const getDirectoryClient = () => new DirectoryClient(APP_URL_HOST, retrieveAuthToken);

export const updateSelf = async (email, firstName, lastName, attributes) => {
  const userProfile = {};

  if (email) userProfile.email = email;
  if (firstName || lastName) {
    userProfile.name = {};
    if (firstName) userProfile.name.givenName = firstName;
    if (lastName) userProfile.name.familyName = lastName;
  }
  if (attributes) {
    userProfile.attributes = attributes;
  }

  const client = getDirectoryClient();
  const result = await client.updateSelf(userProfile);
  return result;
};

import { IS_DEPLOYMENT_MODE } from "../constants/env";
import { useState } from "react";
import { useHubspotChat } from "../featureFlags";

export function useChatIntegration() {
  const chatEnabled = IS_DEPLOYMENT_MODE && !window.location.pathname.includes("/guest/");
  const hubspotChatEnabled = useHubspotChat();
  const [chatScriptsAdded, setChatScriptsAdded] = useState(false);

  const insertChat = () => {
    if (chatEnabled && !chatScriptsAdded) {
      if (hubspotChatEnabled) {
        insertHubspotChat();
        setChatScriptsAdded(true);
      } else {
        runIntercomScript();
        setChatScriptsAdded(true);
      }
    }
  };

  return insertChat;
}

const insertHubspotChat = () => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.id = "hs-script-loader";
  script.async = true;
  script.defer = true;
  script.src = "//js.hs-scripts.com/6720694.js";

  document.body.appendChild(script);

  function onConversationsAPIReady() {
    console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);
  }
  /*
    configure window.hsConversationsSettings if needed.
  */
  window.hsConversationsSettings = {};
  /*
   If external API methods are already available, use them.
  */
  if (window.HubSpotConversations) {
    onConversationsAPIReady();
  } else {
    /*
      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
      These callbacks will be called once the external API has been initialized.
    */
    window.hsConversationsOnReady = [onConversationsAPIReady];
  }
};

const runIntercomScript = () => {
  const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
  window.intercomSettings = { app_id: APP_ID };
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/" + APP_ID;
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
};

/**
 * Build a context object for LaunchDarkly
 * @param {Object} tokenUserInfo
 * @param {string} tokenUserInfo.userId
 * @param {string} tokenUserInfo.email
 * @param {string} currentTenantName
 * @returns
 */
export function buildLdContext(tokenUserInfo, currentTenantName) {
  const { userId, email } = tokenUserInfo;

  if (currentTenantName) {
    return {
      kind: "multi",
      user: {
        key: userId,
        email: email,
        _meta: {
          privateAttributes: ["email"],
        },
      },
      tenant: {
        key: currentTenantName,
      },
    };
  } else {
    return {
      kind: "user",
      key: userId,
      email: email,
      _meta: {
        privateAttributes: ["email"],
      },
    };
  }
}

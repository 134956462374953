import {
  fetchErrorToastHandler,
  generateMessageFromStatus,
  networkErrorToastHandler,
} from "../utils/httpErrorHandler";

export const errorHandler = (error, options) => {
  if (error.response) {
    const { status, data } = error.response;

    if (status && options) {
      const { useToast } = options;
      const message = generateMessageFromStatus({ status, data });
      fetchErrorToastHandler({ message, status, useToast });
    }
  } else if (error.message === "Network Error") {
    networkErrorToastHandler();
  }
};

import { getCognitoJwt } from "./cognito";
import { cleanUserArtifacts } from "./user";
import { jwtDecode } from "jwt-decode";
import { AuthClient } from "@securityjourney/svc-auth";
import { APP_URL_HOST } from "../constants/urls";
import { AFTER_SIGN_OUT_PATH } from "../routes/landing";
import { getIsSjAdminFromStorage, getImpersonatedEmail, getCurrentSelectedTenant } from "./user";
import { isGuestAccess } from "./access";
import { retrieveScormToken } from "../scorm/helpers/scormClient";

const svcAuthClient = new AuthClient(APP_URL_HOST);

// Determine the method for retrieving a new authToken, depending on access type.
// Counter to your intuition, "guest" access uses a "scorm" token, while both "default" and "scorm" use Cognito->svc-auth token.

const TokenRetrieval = {
  method: async options => {
    if (isGuestAccess()) {
      return await retrieveScormToken(options);
    } else {
      return await exchangeCognitoTokenForSvcToken(options);
    }
  },
};

// Methods for saving/retrieving the authToken from local storage
const LocalTokenStorage = {
  save: token => {
    localStorage.setItem("authToken", token);
    localStorage.setItem("svcAuthToken", token);
  },
  get: () => localStorage.getItem("authToken"),
};

/**
 * Called when the app is first loaded to determine if the user is already authenticated.
 * It does this by attempting to retrieve the authToken from local storage and checking if it is expired.
 * If it is expired, it will attempt to exchange the Cognito token for a new authToken.
 * If successful, the new token is saved to local storage and the user is considered authenticated.
 * If unsuccessful (most likely because the Cognito refresh token is expired), the user is considered unauthenticated.
 * @returns boolean
 */
export const rehydrateUserAuthStatus = async () => {
  try {
    const token = await retrieveAuthToken({ noLogOut: true });
    const isAuthenticated = isTokenValid(token);
    return isAuthenticated;
  } catch (e) {
    return false;
  }
};

/**
 * Retrieve the authToken. Handles initial token generation (via Cognito JWT exchange)
 * as well as refreshing the token when if it has expired.

 * @param {boolean} forceRefresh Pass true to force a refresh of the token 
 * @returns {Promise<string} The authToken
 */
export const retrieveAuthToken = async ({ forceRefresh = false, noLogOut = false } = {}) => {
  // First, check to see if we have an unexpired auth token.
  const currentToken = LocalTokenStorage.get();
  if (forceRefresh || !isTokenValid(currentToken)) {
    // Get a new token by exchanging Cognito token, passing along forceRefresh flag
    const newToken = await TokenRetrieval.method({ forceRefresh, noLogOut });
    if (newToken) LocalTokenStorage.save(newToken);
    return newToken;
  } else {
    // Token exists and is not expired - return it
    return currentToken;
  }
};

export const retrieveTokenClaim = async claim => {
  const token = await retrieveAuthToken();
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken[claim];
  }

  return null;
};

export const retrieveTenantList = async () => {
  const svcToken = await retrieveAuthToken();
  if (!svcToken) {
    return [];
  }
  const decodedToken = jwtDecode(svcToken);
  const tenants = decodedToken.available_tenants;
  if (!tenants) {
    return [];
  }
  return tenants.map(tenant => tenant.name);
};

export const retrieveAuthenticatedUserInfo = () => {
  const svcToken = LocalTokenStorage.get();
  if (!svcToken) {
    return null;
  }
  const decodedToken = jwtDecode(svcToken);
  return { email: decodedToken.email, userId: decodedToken.directory_uuid };
};

/**
 * Switches the auth scopes for the current user.
 * This is used when a user switches tenants or impersonates another user.
 * @param {Object} scopes
 */
export const switchAuthScopes = async scopes => {
  const newToken = await exchangeCognitoTokenForSvcToken({ scopes });
  if (newToken) LocalTokenStorage.save(newToken);
};

/**
 * Get the token type (scorm or normal) from a token
 * @param {string} token
 * @returns
 */
const getTokenType = token => {
  if (!token) {
    return null;
  }
  const claims = jwtDecode(token);
  if (claims.iss.includes("svc/scorm")) {
    return "scorm";
  } else {
    return "normal";
  }
};

// Is a token valid? Checks all the things.
const isTokenValid = token => {
  return !isTokenExpired(token) && tokenTypeMatchesAccessType(token);
};

// Determine if the token type matches the access type
const tokenTypeMatchesAccessType = token => {
  const tokenType = getTokenType(token);
  const isGuest = isGuestAccess();
  if (tokenType === "scorm") {
    return isGuest;
  } else {
    return !isGuest;
  }
};

// Check to see if a token is expired. Treats a null token as expired.
export const isTokenExpired = token => {
  if (!token) return true; // an empty token is expired

  const claims = jwtDecode(token);
  if ("exp" in claims) {
    const curr = Math.floor(Date.now() / 1000);
    return claims.exp < curr;
  }

  return false;
};

const exchangeCognitoTokenForSvcToken = async (
  options = { forceRefresh: false, scopes: null, noLogOut: false }
) => {
  const jwtToken = await getCognitoJwt({
    forceRefresh: options.forceRefresh,
    noRedirect: options.noLogOut,
  });
  if (!jwtToken) {
    // If no Cognito token, we can't get an authToken, and user is effectively logged out
    if (!options.noLogOut) {
      cleanUserArtifacts();
      window.location.href = AFTER_SIGN_OUT_PATH;
    } else {
      return null;
    }
  }

  // if we have a Cognito token, exchange it for an authToken
  const scopes = options.scopes || determineScopes();
  const token = await svcAuthClient.exchangeForAccessToken(jwtToken, scopes).promise;

  if (token?.access_token) {
    return token?.access_token;
  } else {
    console.log("Error exchanging Cognito token for authToken", token?.message);
    throw token?.message;
  }
};

const determineScopes = () => {
  const isSjAdmin = getIsSjAdminFromStorage();
  const impersonatedEmail = getImpersonatedEmail();
  const currentSjAdminTenant = getCurrentSelectedTenant();
  const isOperatingInTenant = !!(impersonatedEmail || currentSjAdminTenant);

  return {
    admin: isSjAdmin ? currentSjAdminTenant : null,
    as: isSjAdmin ? impersonatedEmail : null,
    sjAdmin: isSjAdmin && !isOperatingInTenant ? true : null,
  };
};

import { toast } from "react-toastify";
import { ScormClient } from "@securityjourney/svc-scorm";
import { APP_URL_HOST } from "../../constants/urls";
import { EXCHANGE_FAILED } from "../../constants/rpcErrors";
import { getScormParams } from "./contextHelpers";

const svcScormClient = new ScormClient(APP_URL_HOST);

export const retrieveScormToken = async () => {
  const { magicKey, scormIdentity, lessonKey } = getScormParams();
  if (!magicKey || !lessonKey) {
    return null;
  }

  return await svcScormClient
    .getScormToken(magicKey, scormIdentity, lessonKey)
    .promise.catch(e => {
      toast.error(`Error getting token: ${e.data.message}`);
    })
    .then(data => {
      if (data.code == EXCHANGE_FAILED) {
        toast.error(`Error getting token: ${data.message}`);
      } else {
        const { access_token } = data;
        if (access_token) {
          return access_token;
        } else {
          throw Error("Error getting token");
        }
      }
    });
};

export const getScormLesson = async id => {
  return await svcScormClient
    .getLesson(id)
    .promise.catch(e => {
      toast.error(`Error getting lesson: ${e.data.message}`);
    })
    .then(data => {
      return data;
    });
};

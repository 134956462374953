import getClient from "../client";
import { TAGS_CATEGORIES_PATH } from "../routes/user";
import { retrieveCweDirectory, lookupCweTagDataSync } from "../helpers/cweTagData";
import { sortCweTags } from "../user/helpers/catalog/filtersGenerators";

/**
 * Processes and sorts CWE tags for both old and new data structures SHRPA-742
 * @param {Array} cweTags - Array of CWE tag objects
 * @returns {Array} Processed and sorted array of CWE tag objects
 */
const processCweTags = async cweTags => {
  if (!Array.isArray(cweTags)) {
    return [];
  }

  await retrieveCweDirectory();

  const processedTags = cweTags.map(item => {
    const tag = item.cweTag ?? item;
    const cwe_meta = lookupCweTagDataSync(tag.name);

    return {
      ...tag,
      cwe_name: cwe_meta?.Name,
      cwe_description: cwe_meta?.Description,
      lessonGroupsCount: item.lessonGroupsCount ?? null,
    };
  });

  return sortCweTags(processedTags, "name");
};

export default {
  state: {
    tagsData: {
      tagsCategories: [],
      cweTags: [],
    },
    isLoading: true,
  },

  reducers: {
    setData: (state, data) => ({ ...state, tagsData: data }),
    setLoading: (state, isLoading) => ({ ...state, isLoading }),
  },

  effects: dispatch => ({
    async loadAsync() {
      dispatch.tagsCategories.setLoading(true);
      const { data } = await getClient().get(TAGS_CATEGORIES_PATH);

      if (data) {
        const tagsCategories = data.tagsCategories.filter(tc => tc.tags.length);
        const languageTags = tagsCategories.find(tc => tc.name.toLowerCase() === "language");
        const language = languageTags ? languageTags.tags : [];

        window.availableLanguages = language;

        data.cweTags = await processCweTags(data.cweTags);

        const updatedData = { ...data, tagsCategories };
        dispatch.tagsCategories.setData(updatedData);
      }
      dispatch.tagsCategories.setLoading(false);
    },
  }),
};

import { getCurrentSelectedTenant, getIsSjAdminFromStorage } from "./user";
import { retrieveAuthToken } from "./authService";

export const getHeaders = async (noAuth = false) => {
  const authToken = !noAuth ? await retrieveAuthToken() : null;
  const locale = localStorage.getItem("i18nextLng");
  const selectedTenant = getCurrentSelectedTenant();
  const isSjAdmin = getIsSjAdminFromStorage();

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
  }

  if (locale) {
    headers["locale"] = locale;
  }

  if (selectedTenant) {
    headers["SelectedTenant"] = selectedTenant;
  }

  if (isSjAdmin) {
    headers["IsSjAdmin"] = true;
  }

  return headers;
};

export const buildHeaderForUpload = async () => {
  const headers = await getHeaders();
  headers["Content-Type"] = "multipart/form-data";
  return headers;
};

//my.securityjourney.dev
const DEVELOPMENT = "development";

//my.securityjourney.com
const PRODUCTION = "production";

//localhost
const LOCAL = "local";

//cypress || test-pa11y locally
const TEST = "test";

const DEPLOYMENT_MODES = [DEVELOPMENT, PRODUCTION];
const DEVELOPMENT_MODES = [DEVELOPMENT, LOCAL];
const TEST_MODES = [TEST];
const PRODUCTION_MODES = [PRODUCTION];
const LOCAL_MODES = [LOCAL];
const DEPLOYED_DEV_MODES = [DEVELOPMENT];

const REACT_APP_SERVER_HOST = process.env.REACT_APP_SERVER_HOST;

export const RUNNING_API_LOCALLY = REACT_APP_SERVER_HOST === "localhost:3000";
export const IS_DEPLOYMENT_MODE = DEPLOYMENT_MODES.includes(process.env.REACT_APP_ENV);
export const IS_DEV_MODE = DEVELOPMENT_MODES.includes(process.env.REACT_APP_ENV);
export const IS_TEST_MODE = TEST_MODES.includes(process.env.REACT_APP_ENV);
export const IS_PRODUCTION_MODE = PRODUCTION_MODES.includes(process.env.REACT_APP_ENV);
export const IS_LOCAL_MODE = LOCAL_MODES.includes(process.env.REACT_APP_ENV);
export const IS_DEPLOYED_DEV_MODE = DEPLOYED_DEV_MODES.includes(process.env.REACT_APP_ENV);

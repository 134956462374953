export const ACCESS_TYPES = {
  GUEST: "guest",
  SCORM: "scorm",
  DEFAULT: "default",
};

export const getAccessContext = () => {
  if (window.location.pathname.startsWith("/guest")) {
    return ACCESS_TYPES.GUEST;
  } else if (window.location.pathname.startsWith("/scorm")) {
    return ACCESS_TYPES.SCORM;
  } else {
    return ACCESS_TYPES.DEFAULT;
  }
};

export const getAccessTypeForState = () => {
  const accessType = getAccessContext();
  return {
    lessonOnly: accessType !== ACCESS_TYPES.DEFAULT,
    isGuest: accessType === ACCESS_TYPES.GUEST,
  };
};

export const isScormOrGuest = () => getAccessContext() !== ACCESS_TYPES.DEFAULT;
export const isGuestAccess = () => getAccessContext() === ACCESS_TYPES.GUEST;
export const isDefaultAccess = () => getAccessContext() === ACCESS_TYPES.DEFAULT;

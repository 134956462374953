import { CWE_TAG_DATA_PATH } from "../routes/user";
import axios from "axios";

let CWE_DIRECTORY = {};
export const lookupCweTagData = async lessonTagText => {
  const cwe_id = lessonTagTextToCweId(lessonTagText);
  if (!cwe_id) {
    return null;
  }

  const cweDirectory = await retrieveCweDirectory();
  const cweTag = cweDirectory[cwe_id];
  return cweTag;
};

export const lookupCweTagDataSync = lessonTagText => {
  const cwe_id = lessonTagTextToCweId(lessonTagText);
  if (!cwe_id) {
    return null;
  }

  return CWE_DIRECTORY[cwe_id];
};

export const retrieveCweDirectory = async () => {
  if (Object.keys(CWE_DIRECTORY).length === 0) {
    try {
      const result = await axios.get(CWE_TAG_DATA_PATH);
      CWE_DIRECTORY = result.data;
    } catch (error) {
      console.error("Error fetching CWE tag data", error);
    }
  }
  return CWE_DIRECTORY;
};

const lessonTagTextToCweId = lessonTagText => {
  const nameParts = lessonTagText.split("-");
  return nameParts[1];
};

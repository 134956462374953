import React, { useEffect, useState } from "react";
import T from "prop-types";
import { useDispatch, useStore } from "react-redux";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { saveSelectedTenant } from "../../../../helpers/user";

import { retrieveTenantList, retrieveAuthenticatedUserInfo } from "../../../../helpers/authService";
import { toast } from "react-toastify";
import ToastFlashes from "../../../../components/ToastFlashes";

import S from "./ChooseTenantModal.module.scss";

const ChooseTenantModal = ({ isOpen, setIsOpen }) => {
  const [tenants, setTenants] = useState([]);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const {
    dispatch: {
      user: { checkUserLicense, signOut },
    },
  } = useStore();

  useEffect(() => {
    const fetchAuthData = async () => {
      const tenantList = await retrieveTenantList();
      setTenants(tenantList);
      const { email } = retrieveAuthenticatedUserInfo() || {};
      setEmail(email);
    };

    fetchAuthData();
  }, []);

  const handleSelectTenant = async index => {
    const selectedTenant = tenants[index];

    saveSelectedTenant(selectedTenant);
    // After selecting the tenant, check that the user is licensed
    try {
      await checkUserLicense(selectedTenant);
      dispatch.site.setSelectedTenant(selectedTenant);
      setIsOpen(false);
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleLogout = () => {
    signOut();
  };

  return (
    <Modal
      backdropClassName={S.backdrop}
      backdrop={true}
      className={S.root}
      isOpen={isOpen}
      centered
    >
      {tenants.length === 0 && (
        <>
          <ModalHeader tag="div">No organizations available</ModalHeader>
          <ModalBody>
            <p>
              You are logged in as <strong>{email}</strong>. This email address is not associated
              with any organization. If you feel this is in error, please contact your
              administrator.
            </p>
          </ModalBody>
          <Button
            className={S.button}
            block
            outline
            testkey="choose-tenant-button-logout"
            onClick={handleLogout}
          >
            Log Out
          </Button>
        </>
      )}

      {tenants.length > 0 && (
        <>
          <ModalHeader tag="div">Which tenant would you like to log into?</ModalHeader>

          <p className={S.subHeader}>
            You may switch tenants at any time from the profile menu in the upper right.
          </p>

          <ModalBody testkey="select-tenant-modal">
            {tenants.map((tenant, index) => {
              return (
                <Button
                  key={index}
                  className={S.button}
                  block
                  outline
                  testkey={`select-tenant-button-${tenant}`}
                  onClick={() => handleSelectTenant(index)}
                >
                  {tenant}
                </Button>
              );
            })}
          </ModalBody>
          <ToastFlashes />
        </>
      )}
    </Modal>
  );
};

ChooseTenantModal.propTypes = {
  isOpen: T.bool.isRequired,
  setIsOpen: T.func.isRequired,
};

export default ChooseTenantModal;

import { retrieveAuthToken } from "./authService";
import { IdpClient } from "@securityjourney/svc-idp";
import { APP_URL_HOST } from "../constants/urls";
import { SAML_LOGIN_PATH } from "../routes/landing";

export const svcIdpClient = new IdpClient(APP_URL_HOST, retrieveAuthToken);

export const doIdpRedirect = async ({ idp }) => {
  const response = await svcIdpClient.redirect({
    idp,
    redirect_to: `${window.location.origin}${SAML_LOGIN_PATH}`,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  window.location.href = response.data.url;
};

import React, { createContext } from "react";
import T from "prop-types";

export const ToastContext = createContext(null);

export const ToastProvider = ({ children, toastRef }) => (
  <ToastContext.Provider value={toastRef}>{children}</ToastContext.Provider>
);

ToastProvider.propTypes = {
  children: T.node.isRequired,
  toastRef: T.shape({
    current: T.object,
  }).isRequired,
};

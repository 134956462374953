export const GUEST_PATH_PREFIX = "/guest";
export const SCORM_PATH_PREFIX = "/scorm";
export const SCORM_ERROR_PATH = "/error";
export const FULL_SCORM_ERROR_PATH = `${GUEST_PATH_PREFIX}${SCORM_ERROR_PATH}`;

export const SCORM_LANDING_PATH = "/:magicKey/:lessonKey";
export const SCORM_LESSON_PATH = "/belts/:courseId/modules/:lessonId/:step";
export const SCORM_DIRECT_LESSON_PATH = "/modules/:lessonId/:step";

export const scormLessonPath = (context, levelId, lessonId, step = "lesson") =>
  context === "guest"
    ? `${GUEST_PATH_PREFIX}/belts/${levelId || 1}/modules/${lessonId}/${step}`
    : `${SCORM_PATH_PREFIX}/belts/${levelId || 1}/modules/${lessonId}/${step}`;

import { toast } from "react-toastify";
//import { FULL_SCORM_ERROR_PATH } from "../../routes/scorm";

export const handleError = (e, message) => {
  console.error(e);
  toast.error(message, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  /*if (!location.href.includes(FULL_SCORM_ERROR_PATH)) {
    location.href = FULL_SCORM_ERROR_PATH;
  }*/
};

import { uniq } from "lodash";
import { getCount } from "./counter";

const getCamel = s => s.charAt(0).toUpperCase() + s.slice(1);

const generateFilterStructure = ({ lessons, filterName, uniqFilters }) => {
  return uniqFilters.map(l => ({
    label: getCamel(l).split(/_| /).map(getCamel).join(" "),
    value: l,
    count: catalogFilters => getCount(catalogFilters, l, filterName, lessons),
    testKey: l.split(" ").join("_"),
  }));
};

export const getUniqLessonsByKey = (lessons, filterName) => {
  const uniqFilters = uniq(lessons.map(l => l[filterName]))
    .filter(l => l !== "consumed")
    .filter(l => l);

  return generateFilterStructure({ lessons, filterName, uniqFilters });
};

export const sortCweTags = (tags, key) => {
  return [...tags].sort((a, b) => {
    const aValue = key ? a[key] ?? "" : a;
    const bValue = key ? b[key] ?? "" : b;

    const aNumber = aValue.match(/^CWE-(\d+)/)?.[1];
    const bNumber = bValue.match(/^CWE-(\d+)/)?.[1];

    if (aNumber && bNumber) {
      return parseInt(aNumber, 10) - parseInt(bNumber, 10);
    }

    return aValue.localeCompare(bValue);
  });
};

export const getUniqLessonsByArrayKey = (lessons, filterName) => {
  const uniqFilters = uniq(lessons.map(l => l[filterName]).flat());
  const sortedFilters = sortCweTags(uniqFilters);

  return generateFilterStructure({ lessons, filterName, uniqFilters: sortedFilters });
};

export const getCourseName = (lessons, filterName, courses) => {
  const filters = getUniqLessonsByKey(lessons, filterName);

  return filters.map(f => ({
    ...f,
    label: setCourseName({ courses, filter: f }),
  }));
};

const setCourseName = ({ filter, courses }) => {
  const course = Object.values(courses).find(c => c.rankName === filter.value);
  if (course) return course.name;
  return filter.label;
};

import { HAS_TEST, HE_SUB_FILTERS } from "../../../../constants/filters";

export const filterLessonsByHeSubFilter = ({ subFilters, lessonType, lessons }) => {
  if (!subFilters[HE_SUB_FILTERS].length) {
    return lessons.filter(l => lessonType.includes(l.lessonType));
  }

  if (subFilters[HE_SUB_FILTERS].length === 2) {
    return lessons.filter(l => lessonType.includes(l.lessonType));
  }
  if (subFilters[HE_SUB_FILTERS].includes(HAS_TEST)) {
    return lessons.filter(l => lessonType.includes(l.lessonType) && l[HAS_TEST]);
  }
  return lessons.filter(l => lessonType.includes(l.lessonType) && !l[HAS_TEST]);
};

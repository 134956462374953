import { HAS_TEST } from "../../../constants/filters";
import { BY_RELEASE } from "./byRelease";
import {
  getByCweTags,
  getByDifficulty,
  getByExperiment,
  getByLessonType,
  getByQuery,
  getByRankName,
  getByRelease,
  getByResult,
  getFilteredResult,
} from "./filteredLessonsGenerators";

const COUNT = {
  getFilterTypes: (filteredCatalogFilters, catalogFilters, lessons) => {
    const FILTER_TYPES = {
      rankName: {
        doFilter: getByRankName,
        lessons: null,
        doCount: ({ filteredLessons, filterName, value }) => {
          return filteredLessons.filter(l => l[filterName] === value).length;
        },
      },
      difficulty: {
        doFilter: getByDifficulty,
        lessons: null,
        doCount: ({ filteredLessons, filterName, value }) => {
          return filteredLessons.filter(l => l[filterName] === value).length;
        },
      },
      experiment: {
        doFilter: getByExperiment,
        lessons: null,
        doCount: ({ filteredLessons, value }) => {
          return filteredLessons.filter(l => l[value]).length;
        },
      },
      release: {
        doFilter: getByRelease,
        lessons: null,
        doCount: ({ filteredLessons, value }) => BY_RELEASE[value](filteredLessons).length,
      },
      lessonType: {
        doFilter: getByLessonType,
        lessons: null,
        doCount: ({ filteredLessons, filterName, value, subFilter }) => {
          if (!subFilter) return filteredLessons.filter(l => l[filterName] === value).length;
          if (subFilter) {
            if (subFilter === HAS_TEST) {
              return filteredLessons.filter(l => l[filterName] === value && l[HAS_TEST]).length;
            } else {
              return filteredLessons.filter(l => l[filterName] === value && !l[HAS_TEST]).length;
            }
          }
        },
      },

      result: {
        doFilter: getByResult,
        lessons: null,
        doCount: ({ filteredLessons, filterName, value }) => {
          if (value === "in_progress") {
            return filteredLessons.filter(
              l => l[filterName] === value || l[filterName] === "consumed"
            ).length;
          }
          return filteredLessons.filter(l => l[filterName] === value).length;
        },
      },

      cweTags: {
        doFilter: getByCweTags,
        lessons: null,
        doCount: ({ filteredLessons, filterName, value }) => {
          return filteredLessons.filter(l => l[filterName].includes(value)).length;
        },
      },

      heSubFilters: {
        doFilter: () => {},
        lessons: null,
        doCount: () => {
          return 0;
        },
      },
    };

    filteredCatalogFilters.forEach(f => {
      FILTER_TYPES[f].lessons = FILTER_TYPES[f].doFilter(catalogFilters[f], lessons);
    });

    return FILTER_TYPES;
  },

  getFilteredCatalogFilters: (catalogFilters, filterName) => {
    return Object.keys(catalogFilters)
      .filter(n => n !== filterName)
      .filter(n => n !== "query");
  },

  getFilteredLessons: (lessonsByQuery, filterTypes) => {
    return getFilteredResult(
      filterTypes.experiment.lessons,
      lessonsByQuery,
      filterTypes.difficulty.lessons,
      filterTypes.rankName.lessons,
      filterTypes.release.lessons,
      filterTypes.result.lessons,
      filterTypes.lessonType.lessons,
      filterTypes.cweTags.lessons
    );
  },
};

export const getCount = (catalogFilters, value, filterName, lessons, subFilter) => {
  const lessonsByQuery = getByQuery(catalogFilters.query, lessons);

  const filteredCatalogFilters = COUNT.getFilteredCatalogFilters(catalogFilters, filterName);

  const filterTypes = COUNT.getFilterTypes(filteredCatalogFilters, catalogFilters, lessons);

  const filteredLessons = COUNT.getFilteredLessons(lessonsByQuery, filterTypes);

  return filterTypes[filterName].doCount({ filteredLessons, filterName, value, subFilter });
};

import { APP_API_URL } from "../constants/urls";

export const ADMIN_BASE = "/admin";
const API = `${APP_API_URL}/internal_api/v1/admin`;

const API_V2 = `${APP_API_URL}/internal_api/v2/admin`;

// PAGES
export const ACTIVITIES_PATH = "/activities";
export const ACTIVITIES_MENTOR_PATH = "/activities/mentor";
export const ACTIVITIES_JUDGMENT_PATH = "/activities/judgment";
export const ASSIGNMENTS_PATH = "/assignments";
export const ASSIGNMENT_ID_PATH = `${ASSIGNMENTS_PATH}/:id`;
export const ASSIGNMENT_CREATE_PATH = `${ASSIGNMENTS_PATH}/create/new`;
export const ADMINS_PATH = "/admins";
export const CUSTOM_NOTIFICATIONS_PATH = "/notifications";
export const DASHBOARD_PATH = "";
export const FEATURES_PATH = "/features";
export const KNOWLEDGE_ASSESSMENTS_PATH = "/assessments";
export const KNOWLEDGE_ASSESSMENTS_ID_PATH = `${KNOWLEDGE_ASSESSMENTS_PATH}/:id`;
export const KNOWLEDGE_ASSESSMENTS_NEW_ASSESSMENT_PATH = `${KNOWLEDGE_ASSESSMENTS_PATH}/new-assessment`;
export const LEVELS_PATH = "/levels";
export const LEVEL_PATH = "/levels/:id";
export const REPORTING_PATH = "/reporting";
export const REPORTING_PROGRESS_PATH = "/reporting/progress";
export const REPORTING_PROGRESS_ID_PATH = `${REPORTING_PROGRESS_PATH}/:id`;
export const REPORTING_RANKINGS_PATH = "/reporting/rankings";
export const REPORTING_ASSIGNMENTS_PATH = "/reporting/assignments";
export const REPORTING_MICRO_PATH_PATH = "/reporting/micro_roles";
export const REPORTING_MISSIONS_PATH = "/reporting/missions";
export const REPORTING_MICRO_ASSIGNMENTS_PATH = "/reporting/micro_assignments";
export const REPORTING_USAGE_PATH = "/reporting/usage";
export const REPORTING_KNOWLEDGE_ASSESSMENTS_PATH = "/reporting/assessments";
export const REPORTING_KNOWLEDGE_ASSESSMENT_PATH = `${REPORTING_KNOWLEDGE_ASSESSMENTS_PATH}/:id`;
export const REPORTING_KNOWLEDGE_ASSESSMENT_USER_PATH = `${REPORTING_KNOWLEDGE_ASSESSMENT_PATH}/users/:id`;
export const RESOURCES_PATH = "/resources";
export const ROLES_PATH = "/roles";
export const ROLES_PREVIEW_PATH = "/roles_preview";
export const ROLE_NEW_QUEST_PATH = "/roles/new-quest";
export const ROLE_NEW_COURSE_PATH = "/roles/new-path";
export const ROLE_PATH = "/roles/:id";
export const MISSIONS_PATH = "/missions";
export const MISSION_NEW_MISSION_PATH = "/missions/new-mission";
export const MISSION_PATH = "/missions/:id";
export const SETTINGS_PATH = "/settings";
export const USERS_ID_PATH = "/users/:id";
export const USERS_PATH = "/users";
export const TOURNAMENT_PATH = "/tournaments";
export const SJ_ADMIN_DEFAULT_PATH = "";
export const SJ_API_KEYS_PATH = "/api-keys/sj";
export const VIRTUAL_DOJOS_PATH = "/virtual-dojos";
export const VIRTUAL_DOJO_PATH = "/virtual-dojos/:id";
export const VIRTUAL_DOJO_CLONE_PATH = "/virtual-dojos/:id/clone";
export const TRY_US_LESSONS_PATH = "/try-us/lessons";
export const ADMIN_LESSONS_PATH = "/lessons";
export const ADMIN_EXPERIMENTS_PATH = "/experiments";
export const SJ_ADMINS_PATH = "/sj-admins";
export const PATHS_PATH = "/paths";
export const SJ_ADMIN_PATH_PATH = "/paths/:id";
export const AUDIENCES_PATH = "/audiences";
export const REDIRECT_CONFIG_PATH = "/redirect-configuration";
export const REDIRECT_NEW_ENTRY_PATH = `${REDIRECT_CONFIG_PATH}/new-entry`;
export const LATEST_CHANGES_PATH = "/latest_changes";
export const JOURNEY_PLUS_PATH = "/journey_plus";
export const EMAILS_PATH = "/emails";
export const ADMIN_TOURNAMENT_PAGE = `${ADMIN_BASE}/tournaments`;
export const IDP_LIST_PATH = "/idp-configurator";
export const IDP_CONFIG_PATH = `${IDP_LIST_PATH}/:id`;

export const VIRTUAL_DOJO_NEW_PATH = `${ADMIN_BASE}/virtual-dojos/new-dojo`;
export const SJ_ADMIN_PATH_NEW_PATH = `${ADMIN_BASE}/paths/new`;
export const SJ_ADMIN_NEW_LEVEL_PATH = `${ADMIN_BASE}/levels/new`;
export const SJ_ADMIN_LEVELS_PATH = `${ADMIN_BASE}/levels`;
export const sjAdminPath = id => `${ADMIN_BASE}/paths/${id}`;
export const SJ_ADMIN_PATHS_PATH = `${ADMIN_BASE}/paths`;
export const ADMIN_USERS_PATH = `${ADMIN_BASE}/users`;
export const ADMIN_REPORTING_PROGRESS_PATH = `${ADMIN_BASE}/reporting/progress`;
export const API_SJ_API_KEYS_PATH = `${API}/api_keys/sj`;
export const ASSIGNMENT_TABLE = `${ADMIN_BASE}${ASSIGNMENTS_PATH}`;
export const viewAssignmentPage = id => `${ASSIGNMENT_TABLE}/${id}`;

export const adminLevelPath = id => `${ADMIN_BASE}/levels/${id}`;
export const adminRolePath = id => `${ADMIN_BASE}/roles/${id}`;
export const adminMissionPath = id => `${ADMIN_BASE}/missions/${id}`;
export const adminVirtualDojoPath = id => `${ADMIN_BASE}/virtual-dojos/${id}`;
export const adminVirtualDojoClonePath = id => `${ADMIN_BASE}/virtual-dojos/${id}/clone`;
export const adminKnowledgeAssessmentsPath = () => `${ADMIN_BASE}/assessments`;
export const adminKnowledgeAssessmentPath = id => `${ADMIN_BASE}/assessments/${id}`;
export const adminKnowledgeAssessmentNewPath = () => `${ADMIN_BASE}/assessments/new-assessment`;
export const adminKnowledgeAssessmentReportPath = id => `${ADMIN_BASE}/reporting/assessments/${id}`;
export const getReportProgressPath = id => `${ADMIN_BASE}/reporting/progress/${id}`;
export const getUserPath = id => `${ADMIN_BASE}/users/${id}`;
export const getRolePreviewPath = () => `${ADMIN_BASE}/roles_preview`;
export const adminIdpConfigPath = id => `${ADMIN_BASE}/idp-configurator/${id}`;

// API V2
// Tournaments
export const API_ADMIN_TOURNAMENTS_PATH_V2 = `${API_V2}/tournaments`;
export const apiAdminUpdateTournamentPathV2 = id => `${API_V2}/tournaments/${id}`;
export const API_VALIDATE_AUDIENCE = `${API_V2}/tournaments/validate_audience`;
export const apiAdminShowLeaderboard = id => `${API_V2}/tournaments/${id}/show_leaderboard`;

// Audiences
export const API_AUDIENCES_PATH = `${API_V2}/sj_admin/audiences`;
export const apiAudiencesPath = id => `${API_V2}/sj_admin/audiences/${id}`;

// sj admin paths
export const API_SJ_ADMIN_PATHS_PATH_V2 = `${API_V2}/sj_admin/roles`;
export const apiSjAdminPathsPathV2 = id => `${API_V2}/sj_admin/roles/${id}`;

// API V1

// admins
export const API_ADMINS_PATH = `${API}/admins`;
export const API_INVITE_ADMIN_PATH = `${API}/admins/invite`;
export const apiAdminPath = id => `${API}/admins/${id}`;
export const apiAdminGrantPath = id => `${API}/admins/${id}/grant_admin`;

// api keys
export const API_KEYS_PATH = `${API}/api_keys`;

export const apiKeyPath = id => `${API}/api_keys/${id}`;

// features
export const API_CURRENT_PLAN = `${API}/current_plans`;
export const API_FEATURES_PATH = `${API}/features`;
export const apiActivateFeaturePath = id => `${API}/features/${id}/activate`;
export const apiDisableFeaturePath = id => `${API}/features/${id}/disable`;
export const apiActivateFeaturePlanPath = (featureId, planId) =>
  `${API}/features/${featureId}/plans/${planId}/activate`;
export const apiDisableFeaturePlanPath = (featureId, planId) =>
  `${API}/features/${featureId}/plans/${planId}/disable`;

// lessons
export const API_LESSONS_PATH = `${API}/lessons`;
export const API_FULL_LESSONS_PATH = `${API}/lessons/full_index`;
export const adminLessonUpdatePath = id => `${API}/lessons/${id}`;

//experiments
export const API_EXPERIMENTS_PATH = `${API}/experiments`;
export const adminExperimentUpdatePath = id => `${API}/experiments/${id}`;

// levels
export const API_LEVELS_PATH = `${API}/levels`;
export const API_LEVELS_VIEW_TYPES_PATH = `${API}/levels/view_list`;
export const apiLevelPath = id => `${API}/levels/${id}`;

// roles
export const API_ROLES_PATH = `${API}/roles`;
export const API_DELETE_ROLE_PATH = id => `${API}/roles/${id}`;
export const apiRolePath = id => `${API}/roles/${id}`;
export const apiRoleUpdateModePath = id => `${API}/roles/${id}/update_mode`;
export const apiRoleSyncPath = `${API}/roles/sync`;
export const API_UPDATE_MANY_PATH = `${API_V2}/roles/update_many`;

// missions
export const API_MISSIONS_PATH = `${API}/missions`;
export const API_DELETE_MISSION_PATH = id => `${API}/missions/${id}`;
export const apiMissionPath = id => `${API}/missions/${id}`;
export const apiMissionUpdateModePath = id => `${API}/missions/${id}/update_mode`;

// reports
export const API_REPORTS_PROGRESS_PATH = `${API}/reports/progress`;
export const apiReportProgressPath = id => `${API}/reports/progress/${id}`;
export const API_REPORTS_MICRO_ROLES_PATH = `${API}/reports/micro_roles`;
export const API_REPORTS_MISSIONS_PATH = `${API}/reports/missions`;
export const API_REPORTS_USAGE_PATH = `${API}/reports/usage`;
export const API_REPORTS_CUSTOMER_TRANSACTION_PATH = `${API}/reports/customer_transaction`;
export const API_LEVELS_VP_PATH = `${API}/value_proposition/levels`;
export const API_LESSONS_VP_PATH = `${API}/value_proposition/lessons`;
export const API_LEVELS_VP_CSV_PATH = `${API}/value_proposition/levels.csv`;
export const API_LESSONS_VP_CSV_PATH = `${API}/value_proposition/lessons.csv`;
export const API_PROGRESS_FILTERS_COMPONENT = `${API}/reports/progress_filters_component`;

// activities
export const API_ACTIVITY_ACTIONS_PATH = `${API}/activities/actions`;
export const API_ACTIVITY_ACTIONS_RESTORE_PATH = `${API}/activities/actions/restore`;
export const API_ACTIVITY_IMPACTS_PATH = `${API}/activities/impacts`;
export const API_ACTIVITY_IMPACTS_RESTORE_PATH = `${API}/activities/impacts/restore`;
export const API_ACTIVITY_MENTOR_GUIDES_PATH = `${API}/activities/mentor_guides`;
export const API_ACTIVITY_MENTOR_GUIDES_RESTORE_PATH = `${API}/activities/mentor_guides/restore`;
export const API_ACTIVITY_MENTOR_REQUESTS_PATH = `${API}/activities/mentor_requests`;
export const API_ACTIVITY_JUDGMENT_REQUESTS_PATH = `${API}/activities/judgment_requests`;
export const API_ACTIVITY_ASSIGNEES_PATH = `${API}/activities/dojo_activity_assignees`;
export const apiActivityAssigneePath = id => `${API}/activities/dojo_activity_assignees/${id}`;
export const API_MENTOR_REQUEST_ASSIGNEES_PATH = `${API}/activities/mentor_request_assignees`;
export const apiMentorRequestAssigneePath = id =>
  `${API}/activities/mentor_request_assignees/${id}`;

// assignments
export const API_REPORTS_ASSIGNMENTS = `${API}/reports/assignments`;
export const API_REPORTS_MICRO_ASSIGNMENTS = `${API}/reports/micro_assignments`;
export const API_ASSIGNMENT_COMPONENTS_PATH = `${API}/assignment_components`;
export const API_ASSIGNMENT_POLICIES_PATH = `${API}/assignment_policies`;
export const API_MICRO_ASSIGNMENT_POLICIES_PATH = `${API}/micro_assignment_policies`;
export const apiAssignmentPolicyPath = id => `${API}/assignment_policies/${id}`;
export const apiMicroAssignmentPolicyPath = id => `${API}/micro_assignment_policies/${id}`;
export const apiAssignmentPath = id => `${API}/assignment_policies/${id}/assignments`;
export const apiMicroAssignmentPath = id =>
  `${API}/micro_assignment_policies/${id}/micro_assignments`;
export const apiAssignmentDestroyPath = id => `${API}/assignment_policies/${id}/assignments/${id}`;
export const apiMicroAssignmentDestroyPath = id =>
  `${API}/micro_assignment_policies/${id}/micro_assignments/${id}`;
export const apiAssignmentPolicyRulePath = id =>
  `${API}/assignment_policies/${id}/assignment_policy_rules`;
export const apiMicroAssignmentPolicyRulePath = id =>
  `${API}/micro_assignment_policies/${id}/micro_assignment_policy_rules`;

// judgment requests
export const apiJudgmentRequestsPath = id => `${API}/activities/judgment_requests/${id}`;

// users
export const API_EXPORT_USERS_PATH = `${API}/users/export`;
export const API_IMPORT_USERS_PATH = `${API}/users/import`;
export const API_USERS_PATH = `${API}/users`;
export const API_SEARCH_USERS_PATH = `${API}/users/search`;
export const API_CSV_IMPORT_USERS_PATH = `${API_V2}/upload-users`;
export const apiArchiveUserPath = id => `${API}/users/${id}/archive`;
export const apiUnlockUserPath = id => `${API}/users/${id}/unlock`;
export const apiUserExtendTrialPath = id => `${API}/users/${id}/extend_trial`;
export const apiUserPath = id => `${API}/users/${id}`;
export const apiUserImpersonatePath = id => `${API}/users/${id}/impersonate`;
export const apiUserGenerateConfirmTokenPath = id => `${API}/users/${id}/generate_confirm_token`;
export const apiUserGenerateResetTokenPath = id => `${API}/users/${id}/generate_reset_token`;
export const apiUpdateUserPath = id => `${API}/users/${id}`;
export const apiAddLicenseToUserPath = id => `${API}/users/${id}/add_license`;

// csv reports
export const CSV_API_REPORTS_PROGRESS_PATH = `${API}/reports/progress/download`;
export const CSV_API_REPORTS_USAGE_PATH = `${API}/reports/usage.csv`;
export const CSV_API_REPORTS_FUNNEL_PATH = `${API}/reports/funnel.csv`;
export const CSV_API_USERS_PATH = `${API}/users/download`;
export const CSV_FILE_TEMPLATE_PATH = `${API_V2}/upload-users-template.csv`;
export const CSV_FILE_DETAIL_PATH = `${API}/dashboard/csv_file_detail.csv`;
export const CSV_FILE_SUMMARY_PATH = `${API}/dashboard/csv_file_summary.csv`;
export const CSV_API_REPORTS_ASSIGNMENTS_PATH_V2 = `${API_V2}/reports/assignments/download`;
export const CSV_API_REPORTS_MICRO_ASSIGNMENTS_PATH = `${API}/reports/micro_assignments/download`;
export const CSV_API_REPORTS_MICRO_ROLES_PATH = `${API}/reports/micro_roles/download`;
export const CSV_API_REPORTS_LESSON_ATTEMPTS_PATH = `${API}/reports/lesson_attempts/download`;
export const CSV_API_CUSTOMER_TRANSACTIONS_PATH = `${API}/reports/customer_transaction/download`;

// dojo_activities
export const apiActivityPath = id => `${API}/dojo_activities/${id}`;

// settings
export const API_SETTINGS_PATH = `${API}/settings`;
export const API_ACTIVATE_SETTING_PATH = `${API}/settings/activate`;
export const API_DISABLE_SETTING_PATH = `${API}/settings/disable`;
export const API_SET_SETTING_PATH = `${API}/settings/set`;
export const API_SSO_SETTING_PATH = `${API}/settings/sso`;
export const API_SSO_DESTROY_PATH = `${API}/settings/destroy_sso`;

// info
export const API_UPDATE_INFO_PATH = `${API}/infos/set`;
export const API_UPLOAD_LOGO_PATH = `${API}/infos/upload_logo_image`;
export const API_SET_DEFAULT_LOGO_PATH = `${API}/infos/set_default_logo`;

// dashboard
export const API_DASHBOARD_PATH = `${API}/dashboard`;
export const API_DASHBOARD_CHARTS_PATH = `${API}/dashboard/charts`;
export const API_DASHBOARD_STATS_PATH = `${API}/dashboard/stats`;

// reports
export const API_REPORTS_FUNNEL_PATH = `${API}/reports/funnel_period`;
export const API_REPORTS_LEVELS_PATH = `${API}/reports/levels`;

// latest changes
export const API_LATEST_CHANGES_PATH = `${API}/latest_changes`;

// virtual dojos
export const API_VIRTUAL_DOJOS_PATH = `${API}/virtual_dojos`;
export const API_VIRTUAL_DOJOS_CHECK_DOMAINS_PATH = `${API}/virtual_dojos/check_domains`;
export const apiVirtualDojoPath = id => `${API}/virtual_dojos/${id}`;
export const apiImportInVirtualDojoPath = id => `${API}/virtual_dojos/${id}/import`;
export const apiImportInVirtualDojoFromHePath = id => `${API}/virtual_dojos/${id}/import_from_he`;
export const apiVirtualDojoActivationPath = id => `${API}/virtual_dojos/${id}/activation`;
export const apiAnonymizeVirtualDojoPath = id => `${API}/virtual_dojos/${id}/anonymize`;
export const apiGetCloneDataPath = id => `${API}/virtual_dojos/${id}/clone_data`;
export const apiCloneVirtualDojoPath = id => `${API}/virtual_dojos/${id}/clone`;

// sj_admins
export const API_SJ_ADMINS_PATH = `${API}/sj_admin/sj_admins`;
export const apiSjAdminPath = id => `${API}/sj_admin/sj_admins/${id}`;

// sj admin paths
export const API_SJ_ADMIN_PATHS_PATH = `${API}/sj_admin/roles`;
export const apiSjAdminPathsPath = id => `${API}/sj_admin/roles/${id}`;

// sj admin levels
export const API_SJ_ADMIN_LEVELS_PATH = `${API}/sj_admin/levels`;
export const apiSjAdminLevelsPath = id => `${API}/sj_admin/levels/${id}`;

// resources
export const API_CUSTOM_RESOURCES_PATH = `${API}/resources`;
export const API_CUSTOM_RESOURCES_DESTROY_MANY_PATH = `${API}/resources/destroy_many`;
export const apiPathResourceUpdate = id => `${API}/resources/${id}`;

// custom_notifications
export const API_CUSTOM_NOTIFICATIONS_PATH = `${API}/custom_notifications`;
export const API_LAST_CUSTOM_NOTIFICATIONS_PATH = `${API}/custom_notifications/show_last`;
export const API_DESTROY_ALL_CUSTOM_NOTIFICATIONS = `${API}/custom_notifications/destroy_all`;

// custom_latest_changes
export const API_CUSTOM_LATEST_CHANGES_PATH = `${API}/custom_latest_changes`;
export const API_CUSTOM_LATEST_CHANGES_DESTROY_MANY_PATH = `${API}/custom_latest_changes/destroy_many`;
export const apiPathCustomLatestChangesUpdate = id => `${API}/custom_latest_changes/${id}`;

// custom_emails
export const ACTIVATE_EMAILS_PATH = `${API}/custom_emails/activate`;
export const CUSTOM_EMAILS_PATH = `${API}/custom_emails`;
export const SEND_TEST_EMAIL_PATH = `${API}/custom_emails/send_email`;
export const apiPathCustomEmailsChangesUpdate = id => `${API}/custom_emails/${id}`;
export const apiPathCustomEmailsRestore = id => `${API}/custom_emails/${id}/restore`;

// saml_approve
export const API_SAML_APPROVE_PATH = `${API}/saml_approve`;
export const apiSamlApproveUpdatePath = id => `${API}/saml_approve/${id}`;

// micropaths
export const micropathsArchive = id => `${API}/micro_roles/${id}/archive/`;
export const micropathsCreate = `${API}/micro_roles`;
export const micropathsAddToRole = `${API}/micro_roles/add_to_role`;
export const micropathsUpdate = id => `${API}/micro_roles/${id}`;

import { intersectionBy } from "lodash";
import { BY_RELEASE } from "./byRelease";
import { filterLessonsByHeSubFilter } from "./subFilters/heLessonTypeSubfilters";
import { HE_SUB_FILTERS } from "../../../constants/filters";

export const getByQuery = (query, lessons) => {
  return lessons.filter(lesson => {
    const lowerName = lesson.name ? lesson.name.toLowerCase() : "";
    const lowerDescriptor = lesson.description ? lesson.description.toLowerCase() : "";
    const lowerQuery = query.toLowerCase();
    if (lowerName.includes(lowerQuery) || lowerDescriptor.includes(lowerQuery)) {
      return lesson;
    }
  });
};

export const getByExperiment = (experiment, lessons) => {
  if (experiment.length) {
    return lessons.filter(lesson => lesson.hasExperiment);
  }
  return lessons;
};

export const getByDifficulty = (difficulty, lessons) => {
  const isDifficultyChecked = difficulty.length > 0;
  return isDifficultyChecked ? lessons.filter(l => difficulty.includes(l.difficulty)) : null;
};

export const getByRankName = (rankName, lessons) => {
  const isRankNameChecked = rankName.length > 0;
  return isRankNameChecked ? lessons.filter(l => rankName.includes(l.rankName)) : null;
};

export const getByResult = (result, lessons) => {
  const isResultChecked = result.length > 0;
  if (isResultChecked) {
    return lessons.filter(l => {
      if (l.result === "consumed") {
        return result.includes("in_progress");
      }
      return result.includes(l.result);
    });
  }
  return null;
};

export const getByCweTags = (cweTags, lessons) => {
  const isChecked = cweTags.length > 0;

  if (!isChecked) return null;

  const result = lessons.filter(l => l.cweTags.some(ct => cweTags.includes(ct)));

  return result;
};

export const getByRelease = (release, lessons) => {
  const isReleaseChecked = release.length > 0;
  if (!isReleaseChecked) return null;
  const results = release.map(r => BY_RELEASE[r](lessons));
  return intersectionBy(...results, "id");
};

export const getByLessonType = (lessonType, lessons, subFilters = {}) => {
  const isLessonTypeChecked = lessonType.length > 0;
  const subFiltersLength = Object.keys(subFilters).length;
  if (isLessonTypeChecked) {
    if (!subFiltersLength) return lessons.filter(l => lessonType.includes(l.lessonType));
    else {
      if (subFilters[HE_SUB_FILTERS]) {
        lessons = filterLessonsByHeSubFilter({ subFilters, lessons, lessonType });
        return lessons;
      }
    }
  } else {
    return null;
  }
};

export const getFilteredResult = (
  lessonsByExperiment = null,
  lessonsByQuery = null,
  lessonsByDifficulty = null,
  lessonsByRankName = null,
  lessonsByRelease = null,
  lessonsByResult = null,
  lessonsByType = null,
  lessonsByCweTags = null
) => {
  const resultArray = [
    lessonsByExperiment,
    lessonsByQuery,
    lessonsByDifficulty,
    lessonsByRankName,
    lessonsByRelease,
    lessonsByResult,
    lessonsByType,
    lessonsByCweTags,
  ].filter(a => a);
  return intersectionBy(...resultArray, "id");
};

const LOGIN_PATHS = ["", "/logged-out", "/user/sign_in"];

// Methods for saving/retrieving the redirect path from local storage
const Storage = {
  saveRedirectPath: redirectPath => {
    localStorage.setItem("redirectPath", LOGIN_PATHS.includes(redirectPath) ? "/" : redirectPath);
  },
  getRedirectPath: () => localStorage.getItem("redirectPath"),
  removeRedirectPath: () => localStorage.removeItem("redirectPath"),
};

export const savePathForRedirect = (redirectPath = window.location.pathname) => {
  Storage.saveRedirectPath(redirectPath);
};

export const getPathForRedirect = () => {
  return Storage.getRedirectPath();
};

export const removePathForRedirect = () => {
  Storage.removeRedirectPath();
};

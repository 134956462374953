export const HAS_TEST = "hasTest";
export const WITHOUT_TEST = "withoutTest";
export const HE_SUB_FILTERS = "heSubFilters";

export const LESSON_TYPE_FILTER = "lessonType";
export const HACKEDU_FILTER = "hackedu";
export const ONLY_VIDEO_FILTER = "onlyVideo";
export const WITH_EXPERIMENTS_FILTER = "withExperiments";
export const HAS_EXPERIMENT_FILTER = "hasExperiment";
export const RESULT_FILTER = "result";
export const RANK_NAME_FILTER = "rankName";
export const DIFFICULTY_FILTER = "difficulty";
export const CWE_TAGS_FILTER = "cweTags";
export const RELEASE_FILTER = "release";
export const RECENTLY_FiLTER = "recently";
export const CODING_CHALLENGE = "codeChallenge";
export const HACKING_CHALLENGE = "hackingChallenge";

export const CLEAR_FILTERS_ARIA = "Clear All Filters";

const CURRENT_SELECTED_TENANT = "currentSelectedTenant";
const IMPERSONATED_EMAIL = "impersonatedEmail";
const IS_SJ_ADMIN = "isSJAdmin";
const BYPASS_TENANT_SELECTION = "bypassTenantSelection";

export const cleanUserArtifacts = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("svcAuthToken");
  localStorage.removeItem("redirectAfterLoginUrl");
  sessionStorage.removeItem("examInstanceId");
  removeCurrentSelectedTenant();
  removeImpersonatedEmail();
  removeIsSjAdminFromStorage();
};

// Selected tenant storage
export const saveSelectedTenant = tenantName =>
  localStorage.setItem(CURRENT_SELECTED_TENANT, tenantName);
export const getCurrentSelectedTenant = () => localStorage.getItem(CURRENT_SELECTED_TENANT);
export const removeCurrentSelectedTenant = () => localStorage.removeItem(CURRENT_SELECTED_TENANT);

// SJ-Admin state storage
export const setIsSjAdminToStorage = () => localStorage.setItem(IS_SJ_ADMIN, true);
export const removeIsSjAdminFromStorage = () => localStorage.removeItem(IS_SJ_ADMIN);
export const getIsSjAdminFromStorage = () => localStorage.getItem(IS_SJ_ADMIN);

// Impersonation storage
export const saveImpersonatedEmail = email => localStorage.setItem(IMPERSONATED_EMAIL, email);
export const getImpersonatedEmail = () => localStorage.getItem(IMPERSONATED_EMAIL);
export const removeImpersonatedEmail = () => localStorage.removeItem(IMPERSONATED_EMAIL);

// Bypass tenant selection
export const saveBypassTenantSelection = () =>
  sessionStorage.setItem(BYPASS_TENANT_SELECTION, true);
export const removeBypassTenantSelection = () => sessionStorage.removeItem(BYPASS_TENANT_SELECTION);
export const shouldBypassTenantSelection = () => !!sessionStorage.getItem(BYPASS_TENANT_SELECTION);
